import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item', 'tableItems'];

  connect() {
    this.renderTableItems();
  }

  renderTableItems() {
    this.tableItemsTarget.innerHTML = this.itemTargets.map(item => {
      return `<p class="font-recoleta text-primary fs-36px fw-6 mb-0">${item.dataset.value}</p>
              <p class="font-recoleta text-primary fs-3 mb-4">${item.dataset.key}</p>`;
    }).join('');
  }
}
