import { Controller } from '@hotwired/stimulus'
import Highcharts from 'highcharts';

export default class extends Controller {
  static targets = ['container']
  static values = { options: Object, forReport: Boolean }

  connect() {
    const options = this.customizeLabel(this.optionsValue, this.forReportValue)
    Highcharts.chart(this.containerTarget, options)
  }

  customizeLabel(options, forReport) {
    if (options.chart.type != 'pie' && !forReport) { return options; }
    options.legend.labelFormatter = function () {
      return `${this.name.toUpperCase()} ${this.options.quantity || ''} (${this.y}%)`;
    };
    return options;
  }
}
